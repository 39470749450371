<template>
  <div>
    <app-dialog
      v-model="showInfoModal"
      :value.sync="showInfoModal"
      width="500"
      content-class="v-dialog__form"
      persistent
      @keydown.esc="showInfoModal = false ">
      <div class="dialog-content">
        <v-card>
          <v-card-title>
            <div
              v-if="!createLibrary"
              class="d-flex align-center">
              <span v-if="!updateLibraryOrCollection">Create Collection</span>
              <span v-else>{{ setModalTextCollection }}</span>
            </div>
            <div
              v-else
              class="d-flex align-center">
              <span v-if="!updateLibraryOrCollection">Create Library</span>
              <span v-else>{{ setModalTextLibrary }}</span>
            </div>
            <span
              class="sourcery__icon-wrapper black pointer"
              @click="showInfoModal = false">
              <v-icon
                color="white"
                size="15">
                mdi-close
              </v-icon>
            </span>
          </v-card-title>
          <v-card-text
            class="items-wrapper">
            <div
              class="items">
              <div class="item-wrapper">
                <v-text-field
                  v-model="information.name"
                  :disabled="!disableIfSharedOrFollowed.allowed"
                  placeholder="Name"
                  single-line
                  dense
                  class="mt-6"
                  autocomplete="name" />
                <template v-if="updateLibraryOrCollection">
                  <v-text-field
                    v-if="!createLibrary"
                    v-model="information.author"
                    :disabled="!disableIfSharedOrFollowed.allowed"
                    placeholder="Author"
                    single-line
                    dense
                    class="mt-6"
                    autocomplete="on" />
                  <template v-if="!createLibrary">
                    <location-field
                      v-if="showInfoModal"
                      :disabled="!disableIfSharedOrFollowed.allowed"
                      placeholder="Location"
                      class="mt-6"
                      single-line
                      dense
                      :current-location="renderAddressText"
                      @setLocationData="information.location = $event"
                      @setAddressData="information.address = $event" />
                  </template>
                  <v-textarea
                    v-if="verticalScrollInMultilineTextField"
                    v-model="information.description"
                    :disabled="!disableIfSharedOrFollowed.allowed"
                    :rows="3"
                    no-resize
                    dense
                    autocomplete="description"
                    placeholder="Description"
                    class="mt-6" />
                  <v-text-field
                    v-else
                    v-model="information.description"
                    :disabled="!disableIfSharedOrFollowed.allowed"
                    placeholder="Description"
                    single-line
                    dense
                    class="mt-6"
                    autocomplete="description" />
                  <Logos
                    :text-crop="createLibrary ? 'Crop to library logo aspect ratio' : 'Crop to collection logo aspect ratio'"
                    :active-header="information"
                    :disabled="!disableIfSharedOrFollowed.allowed"
                    @addLogo="addLogo" />
                  <div
                    v-if="information.lastModified"
                    class="mt-6">
                    <span>Last Modified</span>
                    <p>{{ setAppropriateFormat(information.lastModified) }}</p>
                  </div>
                  <div>
                    <span>{{ countTitle }}</span>
                    <p>{{ countProduct }}</p>
                  </div>
                  <Followers
                    v-if="!createLibrary"
                    :followers-amount="information.followersInfo.count"
                    :followers="followers" />
                </template>
              </div>
            </div>
          </v-card-text>
          <v-card-actions
            class="pt-6 d-flex justify-end">
            <template>
              <v-btn
                outlined
                @click="showInfoModal = false">
                Cancel
              </v-btn>
              <v-btn
                v-if="!updateLibraryOrCollection"
                :disabled="!info.name"
                class="white--text elevation-0"
                color="blue"
                @click="saveCollectionOrLibrary">
                Save
              </v-btn>
              <app-btn
                v-else
                :access="disableIfSharedOrFollowed"
                btn-class="ml-2 white--text"
                color="blue"
                @click="updateCollectionOrLibrary">
                Update
              </app-btn>
            </template>
          </v-card-actions>
        </v-card>
      </div>
    </app-dialog>
    <AppAddButtonNoMenu
      v-if="showAddButtonActions"
      :color-tooltip="addButtonToolTipColor"
      :disabled="addButtonDisabled"
      :access="addButtonAccess"
      :click-callback="addButtonClicked" />
  </div>
</template>
<script>
import {
  mapState,
  mapMutations,
  mapGetters,
} from 'vuex';
import { TYPE_CREATE } from '@/constants';
import {
  COLLECTION, LIBRARY,
} from '@/constants/cores';
import { TYPE_GEOCODE } from '@/constants/maps';
import { accessHelper } from '@/utils';
import {
  EventBus,
  CREATE_COLLECTION,
} from '@/utils/eventBus';
import setAutocompletePlace from '@/mixins/setAutocompletePlace';
// components
import AppAddButtonNoMenu from '@/components/App/AppAddButtonNoMenu';
import AppFollowersList from '@/components/App/AppFollowersList';
import AppGoogleLocationField from '@/components/App/AppFields/AppGoogleLocationField';
import Logos from './Logos';
export default {
  name: 'CollectionsLibrarysRelocate',
  components: {
    AppAddButtonNoMenu,
    Logos,
    locationField: AppGoogleLocationField,
    Followers: AppFollowersList,
  },
  mixins: [setAutocompletePlace],
  props: {
    showAddButtonActions: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      address: null,
      initializeGoogleAutocompleteOnMounted: false,
      info: {
        name: '',
        description: '',
        lastModified: '',
        location: '',
        author: '',
        address: '',
      },
      logo: null,
      TYPE_GEOCODE,
    };
  },
  computed: {
    ...mapState(['activeHeader', 'updateLibraryOrCollection']),
    ...mapGetters('Collections', ['getPrivateCollectionsListLength']),
    ...mapGetters('FeatureFlags', [
      'verticalScrollInMultilineTextField',
    ]),
    ...mapGetters(['openedInfoModal', 'premiumGroup']),
    ...mapGetters('UserRoles', ['canModifyCustomLibrary', 'canUpdateInfoInCustomLibrary',
      'canCreateCollectionInDefaultLibrary', 'canCreateCollectionInCustomLibrary',
      'canModifyCollection', 'findUserRoleInUpdateLibraryOrCollection',
      'findUserRoleInLibrary', 'canCreateCustomLibrary', 'usersRolesListInUpdateCollection']),
    ...mapGetters(['userId']),
    ...mapGetters('Workspace', ['isFreeWorkspace', 'getActiveWorkspaceRole', 'disabledPersonalWs']),
    renderAddressText() {
      const { location, address } = this.information;
      return address?.formatted_address || location;
    },
    isDefaultLibrary() {
      return this.activeHeader?.kind == 'default';
    },
    isCustomLibrary() {
      return this.activeHeader?.kind == 'custom';
    },
    getCreateCollectionRule() {
      if (this.isCustomLibrary) {
        return this.canCreateCollectionInCustomLibrary;
      }
      return this.canCreateCollectionInDefaultLibrary;
    },
    /**
     * Only when you stay in collections
     */
    getUserLibraryRole() {
      if (!this.activeHeader) return '';
      return this.findUserRoleInLibrary({
        ...this.activeHeader,
        checkIsPublishCollection: false,
      });
    },
    getUserModalLibraryRole() {
      const { updateLibraryOrCollection } = this;
      if (updateLibraryOrCollection) {
        const { members } = updateLibraryOrCollection || {
        };
        if (this.isCollection && members) {
          return this.usersRolesListInUpdateCollection;
        }
        return this.findUserRoleInUpdateLibraryOrCollection;
      }
      return '';
    },
    addButtonToolTipColor() {
      return this.disabledPersonalWs ? 'red' : '';
    },
    addButtonDisabled() {
      if (!this.createLibrary) {
        return this.createLibrary || !this.getCreateCollectionRule(this.getUserLibraryRole).allowed;
      } else {
        const access = this.canModifyCustomLibrary(this.getActiveWorkspaceRole);
        return this.isFreeWorkspace || !this.isDefaultLibrary && !access.allowed;
      }
    },
    addButtonAccess() {
      if (!this.createLibrary) {
        return this.createLibrary || this.getCreateCollectionRule(this.getUserLibraryRole);
      } else {
        const access = this.canModifyCustomLibrary(this.getActiveWorkspaceRole);
        return !this.canCreateCustomLibrary.allowed ? this.canCreateCustomLibrary : access;
      }
    },
    showInfoModal: {
      get() {
        return this.openedInfoModal;
      },
      set(value) {
        this.$emit('changeModal', value);
        this.openInfoModal(value);
        this.setUpdatedLibraryOrCollection(null);
        return value;
      },
    },
    isLimitToCreateCollection() {
      if (this.isFreeWorkspace) {
        return this.createLibrary ? true : this.getPrivateCollectionsListLength >= 5;
      }
      return false;
    },
    information() {
      return this.updateLibraryOrCollection || this.info;
    },
    createLibrary() {
      return this.$route.name === 'libraries';
    },
    isLibrary() {
      return this.updateLibraryOrCollection?.docType == LIBRARY;
    },
    isCollection() {
      return this.updateLibraryOrCollection?.docType == COLLECTION;
    },
    disableIfSharedOrFollowed() {
      if (this.updateLibraryOrCollection) {
        if (this.isLibrary) {
          return this.canUpdateInfoInCustomLibrary(this.getUserModalLibraryRole);
        }
        return this.canModifyCollection(this.getUserModalLibraryRole);
      }
      return accessHelper(true);
    },
    setModalTextCollection() {
      return 'Collection Information';
    },
    setModalTextLibrary() {
      return 'Library Information';
    },
    countProduct() {
      return this.information.collectionCount ?? this.information.productsCount;
    },
    followers() {
      const followers = this.information?.followersInfo?.followers || [];
      return followers.reverse();
    },
    countTitle() {
      return !this.createLibrary ? 'Product count' : 'Collection Count';
    },
  },
  mounted() {
    EventBus.$on(CREATE_COLLECTION, () => {
      this.externalCreateCollectionCB();
    });
  },
  destroyed() {
    EventBus.$off(CREATE_COLLECTION);
  },

  methods: {
    ...mapMutations(['spinner', 'setUpdatedLibraryOrCollection']),
    ...mapMutations(['openInfoModal']),
    ...mapMutations('Collections', [
      'changeUpdateProductMode',
      'changeProductModalVariant',
    ]),
    createProduct() {
      this.changeUpdateProductMode(true);
      this.changeProductModalVariant(TYPE_CREATE);
    },
    setAppropriateFormat(data) {
      return this.$moment(Number(data)).format('YYYY-MM-DD HH:mm');
    },
    async saveCollectionOrLibrary() {
      const data = {
        name: this.info.name,
      };
      this.$emit('saveNewItem', data);
      this.showInfoModal = false;
      this.info.name = '';
    },
    async updateCollectionOrLibrary() {
      const data = {
        ...this.information,
        logo: this.logo,
      };
      this.$emit('updateCollectionOrLibrary', data);
      this.setUpdatedLibraryOrCollection(null);
      this.showInfoModal = false;
      this.logo = null;
    },
    addLogo(item) {
      this.logo = item;
    },
    addButtonClicked() {
      this.showInfoModal = true;
    },
    externalCreateCollectionCB() {
      this.showInfoModal = true;
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .v-text-field__slot {
  textarea {
    overflow: auto !important;
  }
}
::v-deep.v-btn {
  width: 132px;
}
</style>
